import React, { useState, useEffect } from "react";
import ButtonBlock from "./ButtonBlock"; // Import the ButtonBlock component
import "./BlockGame.css"; // Import the CSS file
import confetti from "./Confetti.gif";

function BlockGame() {
  const numButtons = 12; // Number of buttons for this round
  const [buttonStates, setButtonStates] = useState(
    Array(numButtons).fill(false)
  ); // Track state of all buttons
  const [gameStarted, setGameStarted] = useState(false); // Whether the game has started
  const [timer, setTimer] = useState(null); // Game round timer
  const [gameOver, setGameOver] = useState(false); // Track if the game is over
  const [buttonsOnCount, setButtonsOnCount] = useState(0); // Count of buttons that are ON
  const [difficulty, setDifficulty] = useState("medium"); // Default difficulty

  // Track the total score and number of games
  const [totalScore, setTotalScore] = useState(0);
  const [totalGamesPlayed, setTotalGamesPlayed] = useState(0);

  // Confetti state
  const [showConfetti, setShowConfetti] = useState(false);

  // Calculate average score
  const averageScore = totalGamesPlayed > 0 ? totalScore / totalGamesPlayed : 0;

  // Start the game
  const startGame = () => {
    setGameStarted(true);
    setGameOver(false);
    setButtonStates(Array(numButtons).fill(false)); // Reset the button states
    setButtonsOnCount(0); // Reset the buttons "ON" counter
    setTimer(Date.now()); // Start timer when the game starts
    setShowConfetti(false); // Hide confetti when starting new game
  };

  // Reset the game
  const resetGame = () => {
    setGameStarted(false);
    setGameOver(false);
    setButtonStates(Array(numButtons).fill(false)); // Reset all button states
    setButtonsOnCount(0); // Reset "ON" buttons counter
  };

  // Check if the game is won
  useEffect(() => {
    if (buttonsOnCount === numButtons && !gameOver) {
      setGameOver(true); // If all buttons are ON, the game is won

      // Show confetti when the game is won
      setShowConfetti(true);

      // Hide confetti after 3 seconds
      setTimeout(() => setShowConfetti(false), 5000);

      // Update total score and total games played
      setTotalScore(
        (prevTotal) => prevTotal + Math.round((Date.now() - timer) / 1000)
      );
      setTotalGamesPlayed((prevGames) => prevGames + 1);
    }
  }, [buttonsOnCount, gameOver, timer, numButtons]);

  return (
    <div className="container">
      {/* Display total score and average score */}
      <p>Total Score: {totalScore} seconds</p>
      <p>Average Score: {Math.round(averageScore)} seconds</p>
      <p>Total Games Played: {totalGamesPlayed}</p>
      {/* Difficulty dropdown */}
      <div className="difficulty-dropdown">
        <label>Difficulty: </label>
        <select
          value={difficulty}
          onChange={(e) => setDifficulty(e.target.value)}
        >
          <option value="easy">Easy</option>
          <option value="medium">Medium</option>
          <option value="hard">Hard</option>
          <option value="impossible">Impossible</option>
        </select>
      </div>
      {/* Header section for start/next game buttons */}
      <div className="header-button">
        {!gameStarted ? (
          <button className="start-button" onClick={startGame}>
            Start Game
          </button>
        ) : gameOver ? (
          <button className="next-game-button" onClick={resetGame}>
            Next Game
          </button>
        ) : gameStarted ? (
          <button className="progress-game-button" onClick={resetGame}>
            In Progress
          </button>
        ) : null}
      </div>
      {/* Confetti Animation */}

      {/* Confetti Animation */}
      {showConfetti && (
        <div className="confetti-overlay">
          <img src={confetti} alt="Confetti" />
        </div>
      )}

      <div className="buttons-container">
        {/* Render buttons dynamically */}
        {Array.from({ length: numButtons }).map((_, index) => (
          <ButtonBlock
            key={index}
            index={index}
            isOn={buttonStates[index]}
            setButtonState={(index, state) => {
              if (!gameOver) {
                // Only allow state change if game is not over
                const newButtonStates = [...buttonStates];
                newButtonStates[index] = state;
                setButtonStates(newButtonStates);

                // Update count of buttons that are ON
                setButtonsOnCount(
                  state ? buttonsOnCount + 1 : buttonsOnCount - 1
                );
              }
            }}
            gameOver={gameOver} // Pass gameOver to disable button interactions after winning
            gameStarted={gameStarted} // Disable buttons before the game starts
            difficulty={difficulty} // Pass difficulty to ButtonBlock
          />
        ))}
      </div>
    </div>
  );
}

export default BlockGame;
