import React, { useState, useEffect } from "react";
import "./ButtonBlock.css"; // Import the CSS file

function ButtonBlock({
  index,
  isOn,
  setButtonState,
  gameOver,
  gameStarted,
  difficulty,
}) {
  const [buttonState, setButtonStateLocal] = useState(isOn);

  useEffect(() => {
    setButtonStateLocal(isOn);
  }, [isOn]);

  useEffect(() => {
    if (gameStarted && !gameOver) {
      // Set random timeout based on difficulty
      const randomTimeout =
        difficulty === "impossible"
          ? Math.floor(Math.random() * 1000) // Impossible: 0-999ms
          : difficulty === "hard"
          ? Math.floor(Math.random() * 1000) + 100 // Hard: 100-1099ms
          : difficulty === "medium"
          ? Math.floor(Math.random() * 1000) + 200 // Medium: 300-1299ms
          : Math.floor(Math.random() * 1000) + 500; // Easy: 500-1499ms

      // Toggle the button off randomly after the timeout
      const timer = setTimeout(() => {
        if (gameStarted && !gameOver && buttonState) {
          setButtonStateLocal(false);
          setButtonState(index, false); // Update state to "off"
        }
      }, randomTimeout);

      return () => clearTimeout(timer); // Clean up timeout when the component is unmounted or updated
    }
  }, [buttonState, gameStarted, gameOver, difficulty, index, setButtonState]);

  const handleClick = () => {
    if (!gameOver && gameStarted) {
      setButtonStateLocal(!buttonState);
      setButtonState(index, !buttonState);
    }
  };

  return (
    <button
      className="button"
      onClick={handleClick}
      style={{
        backgroundColor: buttonState ? "green" : "red", // Green for ON, Red for OFF
      }}
    >
      {buttonState ? "ON" : "OFF"}
    </button>
  );
}

export default ButtonBlock;
